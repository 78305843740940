import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import emailjs from "emailjs-com";

const NovaIndicacao = ({ user }) => {
    const [formData, setFormData] = useState({ NmPessoa: '', DsContatoEmail: '', Observacao: '', NomeContatoIndicado: '' });
    const [clientes, setClientes] = useState([]);
    const [indicado, setIndicados] = useState([]);
    const [campoHabilitado, setCampoHabilitado] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const serviceID = "service_mpitq4l";
    const templateID = "template_oktkr1k";
    const publicKey = "xqgVziHQ6WkGCqM9t";

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value.toUpperCase() });

        if (name === 'NmPessoa' && value.trim() !== '') {
            setCampoHabilitado(true);
        } else if (name === 'NmPessoa' && value.trim() === '') {
            setCampoHabilitado(false);
        }

        if (name === 'DsContatoIndicado') {
            const indicado = indicados.find((ind) => ind.nome === value);
            if (indicado) {
                setFormData({ ...formData, [name]: value, DsContatoEmail: indicado.email });
            } else {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        const fetchClientes = async () => {
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetClientes');
            if (!response.ok) {
                throw new Error('Erro ao carregar clientes');
            }

            const listaClientes = await response.json();
            setClientes(listaClientes);
        };

        const fetchIndicados = async () => {
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetIndicados');
            if (!response.ok) {
                throw new Error('Erro ao carregar indicações');
            }

            const indicados = await response.json();
            setIndicados(indicados);
        };

        fetchClientes();
        fetchIndicados();
    }, []);

    const handleIndicadoChange = (selectedOption) => {
        if (selectedOption) {
            const indicadoSelecionado = indicado.find(ind => ind.idUsuarioFranquia === selectedOption.value);

            setFormData(prevFormData => ({
                ...prevFormData,
                DsContatoIndicado: indicadoSelecionado ? indicadoSelecionado.idUsuarioFranquia : "",
                NomeContatoIndicado: indicadoSelecionado ? indicadoSelecionado.NomeUsuario : "",
                DsContatoEmail: indicadoSelecionado ? indicadoSelecionado.Email : "",
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                DsContatoIndicado: "",
                NomeContatoIndicado: "",
                DsContatoEmail: "",
            }));
        }
    };

    const optionsIndicados = indicado.map((ind) => ({
        value: ind.idUsuarioFranquia,
        label: ind.NomeUsuario,
        email: ind.Email,
    }));

    const buscarClientePorCNPJ = (event) => {
        const cnpjDigitado = event.target.value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            CNPJ: cnpjDigitado,
        }));

        if (!cnpjDigitado) {
            setCampoHabilitado(false);
            setMensagemErro('');
            setFormData((prevFormData) => ({
                ...prevFormData,
                NmPessoa: '',
            }));
            return;
        }

        const cnpjSemFormatacao = cnpjDigitado.replace(/[^0-9]/g, '');
        if (cnpjSemFormatacao.length !== 14) {
            setCampoHabilitado(false);
            setFormData((prevFormData) => ({
                ...prevFormData,
                NmPessoa: '',
                idPessoa: null,
            }));
            setMensagemErro('');
            return;
        }

        const clienteEncontrado = clientes.find((cliente) => cliente.Cnpj === cnpjDigitado);

        if (clienteEncontrado) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                NmPessoa: clienteEncontrado.Nome,
                idPessoa: clienteEncontrado.idPessoa,
            }));
            setCampoHabilitado(true);
            setMensagemErro('');
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                NmPessoa: '',
                idPessoa: null,
            }));
            setCampoHabilitado(false);
            setMensagemErro('CNPJ não encontrado');
        }
    };

    const handleSalvar = async () => {

        const dataHistorico = new Date().toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          });

        const payload = {
            idUsuarioFranquia: user?.idUsuarioFranquia,
            idUsuarioFranquiaIndicado: formData.DsContatoIndicado,
            idPessoa: formData.idPessoa,
            Obs: formData.Observacao || null,
            Status: 0,
            DataIndicacao: new Date().toISOString().split("T")[0],
            Historico: `${dataHistorico} < Nova Indicação > Lançamento feito pelo usuário: ${user?.NomeUsuario}`
        };

        if (!payload.idUsuarioFranquiaIndicado) {
            alert('Selecione um indicado antes de salvar.');
            return;
        }

        if (!payload.idPessoa) {
            alert('Preencha o CNPJ corretamente e selecione uma empresa.');
            return;
        }

        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostIndicacao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Erro ao salvar os dados.');
        }

        if (formData.DsContatoEmail) {
            const emailParams = {
                to_email: formData.DsContatoEmail,
                from_name: "Indicação RCA Sistemas",
                from_email: "noreply@rcasistemas.com.br",
                subject: "Nova Indicação para Você!",
                message: `
                    Olá, ${formData.NomeContatoIndicado}!

                    Você foi indicado para uma loja no nosso sistema.

                    Loja: ${formData.NmPessoa}

                    Observação: ${formData.Observacao || "Sem observação"}

                    Para mais infomações, acesse seu painel de indicado.
                    https://vendas.rcasistemas.com.br/login

                    Atenciosamente,
                    RCA Sistemas
                `,
            };

            emailjs.send(
                serviceID,
                templateID,
                emailParams,
                publicKey
            )
                .then(() => {
                    alert("E-mail enviado com sucesso para o indicado!");
                })
                .catch((error) => {
                    console.error("Erro ao enviar e-mail:", error);
                    alert("Erro ao enviar e-mail.");
                });
        }
        else {
            alert("Indicação salva com sucesso, mas o indicado não possui um e-mail cadastrado.");
        }

        setFormData({
            CNPJ: '',
            NmPessoa: '',
            DsContatoIndicado: '',
            DsContatoEmail: '',
            Observacao: '',
            NomeContatoIndicado: '',
        });
        setCampoHabilitado(false);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                backgroundColor: '#f8f9fa',
                width: '100%',
                height: '100vh',
                paddingTop: '20px',
                position: 'relative',
            }}
        >
            <div
                style={{
                    width: '100%',
                    maxWidth: '900px',
                    padding: '20px',
                }}
            >
                <div className="card shadow rounded mb-3">
                    <div className="card-header text-white bg-dark">
                        <FontAwesomeIcon icon={faFileCirclePlus} /> Indicação
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <label htmlFor="inputCdCPF_CGC" className="form-label">CNPJ*</label>
                                <InputMask
                                    type="text"
                                    mask="99.999.999/9999-99"
                                    className={`form-control ${mensagemErro ? 'is-invalid' : ''}`}
                                    id="inputCdCPF_CGC"
                                    value={formData.CNPJ}
                                    onChange={buscarClientePorCNPJ}
                                />
                                {mensagemErro && (
                                    <div className="invalid-feedback">
                                        {mensagemErro}
                                    </div>
                                )}
                            </div>

                            <div className="col-12 col-xl-8">
                                <label htmlFor="inputNmPessoa" className="form-label">Razão Social*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={150}
                                    id="inputNmPessoa"
                                    name="NmPessoa"
                                    value={formData.NmPessoa || ''}
                                    disabled
                                />
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="selectIndicados" className="form-label">
                                    Indicado*
                                </label>
                                <Select
                                    id="selectIndicados"
                                    name="DsContatoIndicado"
                                    options={optionsIndicados}
                                    onChange={handleIndicadoChange}
                                    value={optionsIndicados.find(option => option.value === formData.DsContatoIndicado) || null}
                                    placeholder="Selecione ou digite o nome"
                                    isClearable
                                    isSearchable
                                    isDisabled={!campoHabilitado}
                                />
                            </div>

                            <div className="col-12 col-sm-12">
                                <label htmlFor="inputObservacao" className="form-label">
                                    Observação
                                </label>
                                <textarea
                                    className="form-control"
                                    id="inputObservacao"
                                    name="Observacao"
                                    value={formData.Observacao}
                                    onChange={handleChange}
                                    disabled={!campoHabilitado}
                                    rows={4}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button type="submit" className="btn btn-success me-3" disabled={!campoHabilitado} onClick={handleSalvar}><FontAwesomeIcon icon={faFloppyDisk} /> Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NovaIndicacao;