import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const InstallPWAButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isPWAInstalled, setIsPWAInstalled] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };

        const checkIfPWAInstalled = () => {
            if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
                setIsPWAInstalled(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        checkIfPWAInstalled();

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) return;
        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
            console.log('Usuário aceitou a instalação do PWA');
        } else {
            console.log('Usuário rejeitou a instalação do PWA');
        }
        setDeferredPrompt(null);
    };

    return (
        <>
            {!isPWAInstalled && deferredPrompt && (
                <button className="btn btn-secondary mt-3 d-flex align-items-center" onClick={handleInstallClick}>
                    <FontAwesomeIcon icon={faDownload} className="me-2" /> Instalar APP
                </button>
            )}
        </>
    );
};

export default InstallPWAButton;
