import { React, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

const ModalAssociarProdutoFranquiado = ({ showModal, handleCloseModal, handleSalvarProdutoFranquiado }) => {
    const [formData, setFormData] = useState({});
    const [produtos, setProdutos] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toUpperCase() });
    };

    const truncate = (text, maxLength) =>
        text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    const handleSalvarProduto = async (produtoFranquiadoSalvar) => {



        const produtoAdd = produtos.filter((p) => {
            return p.IdProduto == produtoFranquiadoSalvar.IdProduto;
        });

        // console.log(produtoFranquiadoSalvar, produtoAdd);

        //produtoFranquiadoSalvar.IdCaracteristica = franquiadoSelecionado.idCaracteristica;
        produtoFranquiadoSalvar.DescricaoProduto = produtoAdd[0].Nome;

        handleSalvarProdutoFranquiado(produtoFranquiadoSalvar);
    };

    useEffect(() => {
        const fetchProdutos = async () => {
            try {
                //const response = await fetch('http://localhost:63299/franquia/getProduto?nomeProduto=');
                //const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/getProduto?nomeProduto=');
                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetProdutosAutorizados');
                if (!response.ok) {
                    throw new Error('Erro ao carregar produtos');
                }
                const data = await response.json();

                //console.log(data);
                //const ret = data.filter((prod) => {
                //    return (prod.IdCaracteristicaPessoa != null);
                //});
                //console.log(ret);
                setProdutos(data);
            } catch (error) {
                console.error('Erro ao carregar Produtos:', error);
            } finally {

            }
        };

        fetchProdutos();

    }, []);

    return (
        <div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-white bg-dark">
                                <h5 className="modal-title"><FontAwesomeIcon icon={faCircleUser} /> Associar produtos a franquia</h5>
                                <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}>
                                    {/*<span aria-hidden="true">&times;</span>*/}
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="row d-flex justify-content-center">
                                            {produtos.map((prod, idx) => (
                                                <div key={prod.IdProduto} className={`card btn col-auto m-3 p-0  ${(prod.Principal) ? 'bg-primary text-white' : 'bg-warning'} }`} onClick={() => { handleSalvarProduto(prod) }}>
                                                    <div className="card-body ">
                                                        <div className="align-items-center">
                                                            <img width="60px" height="60px" className="img-fluid rounded p-1" alt={prod.Nomw} title={prod.Nome} src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + prod.Nome} />
                                                        </div>
                                                    </div>
                                                    <span
                                                        className={`position-absolute top-100 start-50 translate-middle badge rounded-pill ${(prod.Principal) ? 'bg-primary text-white' : 'bg-warning text-dark'}`}
                                                        title={prod.Nome}
                                                    >
                                                        {truncate(prod.Nome, 15)}
                                                        <span className="visually-hidden">unread messages</span>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    {/*<div className="col-12 col-md-7">*/}
                                    {/*    <label htmlFor="selectCarac" className="form-label">Produto</label>*/}
                                    {/*    <select className="form-select form-select-lg" id="selectCarac" aria-label=".form-select-lg caract" name="IdProduto" onChange={handleChange} value={formData.IdProduto}  >*/}
                                    {/*        <option >Selecione</option>*/}
                                    {/*        {*/}
                                    {/*            produtos.map((prod) => (*/}
                                    {/*                <option key={prod.IdProduto} value={prod.IdProduto}>*/}
                                    {/*                    <div className="align-items-center">*/}
                                    {/*                        <img width="20px" height="20px" className="img-fluid rounded p-1" src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + prod?.Nome} />*/}
                                    {/*                        {prod.Nome}*/}
                                    {/*                    </div>*/}

                                    {/*                </option>*/}
                                    {/*            ))*/}
                                    {/*        }*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 col-md-5">*/}
                                    {/*    <label htmlFor="inputValor" className="form-label">Valor Sugerido</label>*/}
                                    {/*    <div className="input-group">*/}
                                    {/*        <span className="input-group-text" id="basic-addon1">R$</span>*/}
                                    {/*        <input type="number" className="form-control " id="inputValor" name="Valor" onChange={handleChange} value={parseFloat(formData?.Valor ?? 0)} />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {/*<div className="modal-footer">*/}
                            {/*    <button type="button" className="btn btn-success" onClick={() => { handleSalvarProduto(formData) }}>*/}
                            {/*        Salvar*/}
                            {/*    </button>*/}
                            {/*    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>*/}
                            {/*        Fechar*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalAssociarProdutoFranquiado;