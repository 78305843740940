import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faHotel,
  faLocationDot,
  faAddressBook,
  faCirclePlus,
  faBoxOpen,
  faTrash,
  faBrazilianRealSign,
} from "@fortawesome/free-solid-svg-icons";
import ModalAssociarProdutoFranquiado from "../components/ModalAssociarProdutoFranquiado";
import InputMask from "react-input-mask";
import { requestOptionsPOST } from "../utils/RequestOptions";

const NovoCliente = ({ user, loading }) => {
  const [formData, setFormData] = useState({});
  const [produtosFranquiado, setprodutosFranquiado] = useState([]);
  const [valorTotalProduto, setvalorTotalProduto] = useState(0.0);
  const [podeAddProd, setpodeAddProd] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertErro, setshowAlertErro] = useState(false);
  const [
    showModalAssociarProdutoFranquiado,
    setShowModalAssociarProdutoFranquiado,
  ] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const [crt, setCrt] = useState("");
  const [enquadramento, setEnq] = useState("");
  const [requisicaoEnviada, setRequisicaoEnviada] = useState(false);

  const handleShowModalAssociarProdutoFranquiado = () => {
    setShowModalAssociarProdutoFranquiado(true);
  };

  const handleCloseModalAssociarProdutoFranquiado = () => {
    setShowModalAssociarProdutoFranquiado(false);
  };

  const handleAdicionarProdutoFranquiado = async (produtoFranquiadoSalvar) => {
    if ((produtoFranquiadoSalvar.IdProduto ?? "") == "") {
      //setShowAlert(true);
      return;
    }

    if (
      produtosFranquiado.filter((p) => {
        return p.IdProduto == produtoFranquiadoSalvar.IdProduto;
      }).length > 0
    ) {
      return;
    }

    produtoFranquiadoSalvar.IdCaracteristica = user.idCaracteristica;
    produtoFranquiadoSalvar.IdProdutoFranquia = null;
    produtoFranquiadoSalvar.Valor = "0.00";

    //console.log('Produto que ira adicionar', produtoFranquiadoSalvar);

    setprodutosFranquiado([...produtosFranquiado, produtoFranquiadoSalvar]);
    //console.log(produtosFranquiado);
    handleCloseModalAssociarProdutoFranquiado();
  };

  const handleRemoverProdutoLancamento = async (produtoFranquiadoRemover) => {
    const novaLista = produtosFranquiado.filter(
      (objeto) => objeto.IdProduto !== produtoFranquiadoRemover.IdProduto
    );

    setprodutosFranquiado(novaLista);
  };

  const handleCrtChange = (e) => {
    setCrt(e.target.value);
  };

  const handleEnqChange = (e) => {
    setEnq(e.target.value);
  };

  const isValidInput = (input) => {
    const safeRegex = /^[\p{L}\p{N}\s.,!?@#%&()\[\]{}_\-]*$/u;
    return safeRegex.test(input);
  };

  const handleSubmit = async (e) => {
    const user = JSON.parse(localStorage.getItem("user"));

    e.preventDefault();
    try {
      if (
        (formData.CdCPF_CGC?.trim() ?? "") == "" ||
        (formData.NomeCidade?.trim() ?? "") == "" ||
        (formData.IdUF?.trim() ?? "") == "" ||
        (formData.NomeBairro?.trim() ?? "") == "" ||
        (formData.NrLogradouro?.trim() ?? "") == "" ||
        (formData.NmLogradouro?.trim() ?? "") == "" ||
        (formData.NmPessoa?.trim() ?? "") == "" ||
        (formData.NmCurto?.trim() ?? "") == "" ||
        (formData.DsContatoNome?.trim() ?? "") == "" ||
        (formData.DsContatoTelefone?.trim() ?? "") == "" ||
        (formData.DsContatoEmail?.trim() ?? "") == "" ||
        ((crt?.trim() ?? "") != "" && (enquadramento?.trim() ?? "") == "")
      ) {
        setShowAlert(true);
        return;
      }

      if (crt === "") {
        setShowAlert(true);
        return;
      }

      formData.ValorImplantacao = Number(
        Number(
          (formData.ValorImplantacao || "0")
            .toString()
            .replace("R$ ", "")
            .replace(",", ".")
        ).toFixed(2)
      );

      formData.produtomodel = [];

      produtosFranquiado.forEach((p) => {
        formData.produtomodel.push({
          IdProduto: p.IdProduto,
          NmProduto: p.DescricaoProduto,
          VlProduto: p.Valor,
          TipoLancamento: "0",
        });
      });

      formData.usuariomodel = {
        IdUsuario: user?.idUsuario ?? "00A0000001",
        IdCategoria: "0000000004",
        NmPessoa: user?.NomeUsuario ?? "SUPERVISOR GERAL DO SISTEMA",
        CdChamada: "100147",
        IdPessoa: "00A00000QS",
      };

      formData.TpPessoa = "J";
      formData.DsObservacao = "";
      formData.IdBairro = "";
      formData.IdCidade = "";
      formData.IdPais = "076";
      // formData.DtNascimento = Date.now();
      formData.previsaoimplantacao = Date.now();
      formData.idUsuarioFranquia = user.idUsuarioFranquia;
      formData.CRT = crt;
      formData.Enquadramento = enquadramento;
      formData.DtNascimento = null;
      formData.CdCEP = formData?.CdCEP?.replaceAll(".", "")?.replaceAll(
        "-",
        ""
      );
      // formData.DsParecer = "Valor Implantação: " + formData.ValorImplantacao + "\n" + formData.DsParecer + "\nTESTE DE LANÇAMENTO FEITO PELO SITE DE GETÃO DA REDE BEM - FEITO POR SÉRGIO"

      formData.DsParecer = JSON.parse(
        JSON.stringify(formData?.DsParecer ?? "")
      );

      const dsParecerValido = isValidInput(formData.DsParecer);

      if (!dsParecerValido) {
        alert("O parecer informado é inválido. Corrija-o antes de prosseguir com o envio.");
        return;
      }

      if (user?.Perfil == 2 || user?.Perfil == 5) {
        formData.Status = 1;
      }

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        redirect: "follow",
      };

      if (requisicaoEnviada) return;

      setRequisicaoEnviada(true);

      const response = fetch(
        "https://api.erp.rcasistemas.com.br/franquia/PostCliente",
        requestOptions
      );

      response.then((resp) => {
        if (!resp.ok) {
          throw new Error("Erro ao enviar o formulário");
        }

        resp
          .json()
          .then((clienteAutorizar) => {
            if (user?.Perfil === 2) {
              clienteAutorizar.Status = 1;

              const responseAutorizar = fetch(
                "https://api.erp.rcasistemas.com.br/franquia/PostAutorizacaoCliente",
                requestOptionsPOST(clienteAutorizar)
              );

              responseAutorizar
                .then((respAut) => {
                  if (!respAut.ok) {
                    throw new Error("Erro ao autorizar o cliente");
                  }

                  console.log("Cliente autorizado com sucesso");

                  window.location.replace("/");
                })
                .catch((error) => {
                  console.error("Erro ao autorizar cliente:", error);
                });
            } else {
              window.location.replace("/");
            }
          })
          .catch((error) => {
            console.error("Erro ao converter resposta para JSON:", error);
            setRequisicaoEnviada(false);
          });
      });
    } catch (error) {
      setshowAlertErro(true);
      setRequisicaoEnviada(false);
      console.error("Erro ao enviar o formulário:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() });
  };

  const validarInputCnpj = async (e) => {
    const { value } = e.target;

    const cnpj = value
      .replace(",", "")
      .replace(".", "")
      .replace(".", "")
      .replace("-", "")
      .replace("/", "");
    //console.log(cnpj);
    //const apiUrl = 'http://localhost:63299/franquia/GetClienteReceitaWs?cnpj=' + cnpj;
    const apiUrl =
      "https://api.erp.rcasistemas.com.br/franquia/GetClienteReceitaWs?cnpj=" +
      cnpj;

    await fetch(apiUrl)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == "OK") {
          if (!result?.simples?.optante && !result?.simei?.optante) {
            setCrt("RegimeNormal");
          } else if (result?.simples?.optante && !result?.simei?.optante) {
            setCrt("SimplesNacional");
          } else if (result?.simples?.optante && result?.simei?.optante) {
            setCrt("SimplesNacionalMei");
          } else {
            setCrt("");
          }

          setFormData((formData) => ({
            ...formData,
            CdCPF_CGC: result?.cnpj?.toUpperCase(),
            NmPessoa: result?.nome?.toUpperCase().substring(0, 50),
            NmLogradouro: result?.logradouro?.toUpperCase().substring(0, 50),
            NrLogradouro: result?.numero?.toUpperCase(),
            NomeCidade: result?.municipio?.toUpperCase(),
            NomeBairro: result?.bairro?.toUpperCase(),
            IdUF: result?.uf?.toUpperCase(),
            CdCEP: result?.cep?.toUpperCase(),
            DsContatoEmail: "", //result?.email?.toUpperCase(),
            DsContatoTelefone: "", //result?.telefone?.toUpperCase(),
            DsContatoTelefone2: "", //result?.telefone?.toUpperCase(),
            NmCurto: (result?.fantasia == ""
              ? result?.nome
              : result?.fantasia
            )?.toUpperCase(),
            DsComplemento: result?.complemento?.toUpperCase(),
            DsContatoNome: "", //(result?.qsa.length > 0) ? result?.qsa[0]?.nome : "",
            DtNascimento: "", //(result?.data_situacao ?? '')
            CRT: crt,
            Enquadramento: enquadramento,
          }));
        }
      })
      .catch((error) => console.error("error", error));
  };

  const handleBlurInputValor = async (e, selecionado) => {
    const { name, value } = e.target;

    const valorNumerico = parseFloat(value);
    const valorFormatado = valorNumerico.toFixed(2);

    e.target.value = valorFormatado;

    produtosFranquiado[produtosFranquiado.indexOf(selecionado)].Valor =
      valorFormatado;

    setprodutosFranquiado(produtosFranquiado);

    setvalorTotalProduto(
      produtosFranquiado.reduce((acc, prod) => {
        return acc + parseFloat(prod?.Valor ?? 0);
      }, 0)
    );
  };

  const buscarGeolocalizaocao = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocalização não suportada pelo seu navegador");
    }
  };

  useEffect(() => {
    const fetchProdutosFranquiados = async () => {
      if (user?.idCaracteristica == null) return;

      //const response = await fetch('http://localhost:63299/franquia/GetProdutosFranquia?IdCaracteristica=' + user.idCaracteristica);
      const response = await fetch(
        "https://api.erp.rcasistemas.com.br/franquia/GetProdutosFranquia?IdCaracteristica=" +
          user.idCaracteristica
      );

      if (!response.ok) {
        throw new Error("Erro ao carregar produtos da franquia");
      }
      const produtoFranquia = await response.json();

      // console.log(produtoFranquia);
      if (produtoFranquia.length == 0) {
        setpodeAddProd(true);
      }

      setprodutosFranquiado(produtoFranquia);
    };

    fetchProdutosFranquiados();
    setvalorTotalProduto(0.0);
  }, [user]);

  const valorMensalidade = parseFloat(valorTotalProduto) || 0;
  const valorImplantacao = formData.ValorImplantacao
    ? parseFloat(
        formData.ValorImplantacao.replace("R$ ", "").replace(",", ".")
      ) || 0
    : 0;
  const total = valorMensalidade + valorImplantacao;

  return (
    <form className="form" onSubmit={handleSubmit}>
      {showAlert && (
        <div className="alert alert-danger" role="alert">
          Por favor, preencha todos os campos.
        </div>
      )}

      {showAlertErro && (
        <div className="alert alert-danger" role="alert">
          Falha no envio dos dados, aguarde um instante e tente novamente
        </div>
      )}

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card shadow rounded mb-3">
            <div className="card-header text-white bg-dark">
              <FontAwesomeIcon icon={faHotel} /> Dados Empresa
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <label htmlFor="inputCdCPF_CGC" className="form-label">
                    CNPJ*
                  </label>
                  <InputMask
                    type="text"
                    mask="99.999.999/9999-99"
                    className="form-control "
                    id="inputCdCPF_CGC"
                    name="CdCPF_CGC"
                    value={formData.CdCPF_CGC || ""}
                    onChange={handleChange}
                    onBlur={validarInputCnpj}
                  />
                </div>

                <div className="col-6 col-xl-3">
                  <label
                    htmlFor="inputNrinscricaoEstadual"
                    className="form-label"
                  >
                    IE
                  </label>
                  <input
                    type="number"
                    className="form-control "
                    id="inputNrinscricaoEstadual"
                    name="NrinscricaoEstadual"
                    value={formData.NrinscricaoEstadual || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-6 col-xl-3">
                  <label
                    htmlFor="inputNrinscricaoMunicipal"
                    className="form-label"
                  >
                    IM
                  </label>
                  <input
                    type="number"
                    className="form-control "
                    id="inputNrinscricaoMunicipal"
                    name="NrinscricaoMunicipal"
                    value={formData.NrinscricaoMunicipal || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-xl-12">
                  <label htmlFor="inputNmPessoa" className="form-label">
                    Razão Social*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    maxLength={150}
                    id="inputNmPessoa"
                    name="NmPessoa"
                    value={formData.NmPessoa || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-xl-12">
                  <label htmlFor="inputNmCurto" className="form-label">
                    Nome Fantasia*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    maxLength={150}
                    id="inputNmCurto"
                    name="NmCurto"
                    value={formData.NmCurto || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-xl-6">
                  <label htmlFor="selectCrt" className="form-label">
                    CRT (Código de regime tributário)*
                  </label>
                  <select
                    className="form-select"
                    name="selectCrt"
                    value={crt}
                    onChange={handleCrtChange}
                  >
                    <option key="" value="">
                      Selecione
                    </option>
                    <option key="1" value="SimplesNacional">
                      Simples Nacional
                    </option>
                    <option key="2" value="SimplesNacionalExcessoSublimite">
                      Simples Nacional – excesso de sublimite de receita bruta
                    </option>
                    <option key="3" value="RegimeNormal">
                      Regime Normal
                    </option>
                    <option key="4" value="SimplesNacionalMei">
                      Simples Nacional MEI
                    </option>
                  </select>
                </div>

                <div className="col-12 col-xl-6">
                  <label htmlFor="selectEnq" className="form-label">
                    Faixa Enquadramento
                  </label>
                  <select
                    className="form-select"
                    name="selectEnq"
                    value={enquadramento}
                    onChange={handleEnqChange}
                  >
                    {crt == "RegimeNormal" ? (
                      <>
                        <option key="" value="">
                          Selecione
                        </option>
                        <option key="98" value="LucroPresumido">
                          LUCRO PRESUMIDO
                        </option>
                        <option key="99" value="LucroReal">
                          LUCRO REAL
                        </option>
                      </>
                    ) : (
                      crt !== "" && (
                        <>
                          <option key="" value="">
                            Selecione
                          </option>
                          <option key="101" value="ReceitaBrutaate180">
                            Até 180.000,00
                          </option>
                          <option key="102" value="ReceitaBrutade180a360">
                            De 180.000,01 a 360.000,00
                          </option>
                          <option key="103" value="ReceitaBrutade360a720">
                            De 360.000,01 a 720.000,00
                          </option>
                          <option key="104" value="ReceitaBrutade720a1800">
                            De 720.000,01 a 1.800.000,00
                          </option>
                          <option key="105" value="ReceitaBrutade1800a3600">
                            De 1.800.000,01 a 3.600.000,00
                          </option>
                          <option key="106" value="ReceitaBrutade36000a4800">
                            De 3.600.000,01 a 4.800.000,00
                          </option>
                        </>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="card shadow rounded mb-3">
            <div className="card-header text-white bg-dark">
              <FontAwesomeIcon icon={faLocationDot} /> Endereço
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <label htmlFor="inputNmLogradouro" className="form-label">
                    Endereço*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    maxLength={150}
                    id="inputNmLogradouro"
                    name="NmLogradouro"
                    value={formData.NmLogradouro || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-3 col-xl-2">
                  <label htmlFor="inputNrLogradouro" className="form-label">
                    Nº*
                  </label>
                  <input
                    type="number"
                    className="form-control "
                    id="inputNrLogradouro"
                    name="NrLogradouro"
                    value={formData.NrLogradouro || ""}
                    onChange={handleChange}
                  />
                </div>

                {/*<div className="col-5 col-xl-3">*/}
                {/*    <label htmlFor="inputTpLogradouro" className="form-label">Tp.Logradouro</label>*/}
                {/*    <input type="text" className="form-control " id="inputTpLogradouro" name="TpLogradouro" value={formData.TpLogradouro || ''} onChange={handleChange} />*/}
                {/*</div>*/}

                <div className="col-4 col-xl-3">
                  <label htmlFor="inputCdCEP" className="form-label">
                    Cep*
                  </label>
                  <InputMask
                    type="text"
                    mask="99999-999"
                    className="form-control "
                    id="inputCdCEP"
                    name="CdCEP"
                    value={formData.CdCEP || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-5 col-xl-3">
                  <label htmlFor="inputDsComplemento" className="form-label">
                    Complemento
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="inputDsComplemento"
                    maxLength={150}
                    name="DsComplemento"
                    value={formData.DsComplemento || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-7 col-xl-3">
                  <label htmlFor="inputIdBairro" className="form-label">
                    Bairro*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="inputIdBairro"
                    maxLength={150}
                    name="NomeBairro"
                    value={formData.NomeBairro || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-7 col-xl-6">
                  <label htmlFor="inputIdCidade" className="form-label">
                    Cidade*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="inputIdCidade"
                    maxLength={150}
                    name="NomeCidade"
                    value={formData.NomeCidade || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-5 col-xl-2">
                  <label htmlFor="inputIdUF" className="form-label">
                    UF*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="inputIdUF"
                    name="IdUF"
                    maxLength={2}
                    value={formData.IdUF || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="card shadow rounded mb-3">
            <div className="card-header text-white bg-dark">
              <FontAwesomeIcon icon={faAddressBook} /> Contato
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-xl-12">
                  <label htmlFor="inputDsContatoNome" className="form-label">
                    Nome Contato*
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="inputDsContatoNome"
                    name="DsContatoNome"
                    maxLength={150}
                    value={formData.DsContatoNome || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-xl-6">
                  <label
                    htmlFor="inputDsContatoTelefone"
                    className="form-label"
                  >
                    Telefone 1*
                  </label>
                  <InputMask
                    type="tel"
                    mask="(99) 99999 9999"
                    className="form-control "
                    id="InputDsContatoTelefone"
                    name="DsContatoTelefone"
                    value={formData.DsContatoTelefone || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-xl-6">
                  <label
                    htmlFor="inputDsContatoTelefone2"
                    className="form-label"
                  >
                    Telefone 2
                  </label>
                  <InputMask
                    type="tel"
                    mask="(99) 99999 9999"
                    className="form-control "
                    id="InputDsContatoTelefone2"
                    name="DsContatoTelefone2"
                    value={formData.DsContatoTelefone2 || ""}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 col-xl-8">
                  <label htmlFor="inputDsContatoEmail" className="form-label">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="form-control "
                    id="inputDsContatoEmail"
                    name="DsContatoEmail"
                    value={formData?.DsContatoEmail?.toLowerCase() || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card shadow rounded mb-3">
            <div className="card-header text-white bg-dark">
              <div className="d-flex justify-content-between">
                <div className="card-title ">
                  <FontAwesomeIcon icon={faBoxOpen} /> Produtos
                </div>
                <div
                  className="btn btn-toolbar btn-dark"
                  onClick={handleShowModalAssociarProdutoFranquiado}
                >
                  <FontAwesomeIcon icon={faCirclePlus} />
                </div>
                {/*{(!podeAddProd)*/}
                {/*    ? ''*/}
                {/*    : <div className="btn btn-toolbar btn-dark" onClick={handleShowModalAssociarProdutoFranquiado}><FontAwesomeIcon icon={faCirclePlus} /></div>*/}
                {/*}*/}
              </div>
            </div>
            <div className="table-responsive shadow">
              <div className="row d-flex justify-content-center">
                {produtosFranquiado.map((prod, idx) => (
                  <div
                    key={prod.IdProduto}
                    className={`card col-4 m-3 p-0  ${
                      prod.Principal ? "bg-primary text-white" : "bg-warning"
                    } }`}
                  >
                    <div className="card-header d-flex justify-content-end p-0">
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        aria-label="Close"
                        onClick={() => handleRemoverProdutoLancamento(prod)}
                      ></button>
                    </div>
                    <div className="card-body ">
                      <img
                        width="55px"
                        height="55px"
                        className="rounded mx-auto d-block mb-1"
                        alt={prod.Nomw}
                        src={
                          "http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=" +
                          prod.Nome
                        }
                      />
                      <input
                        type="number"
                        className="form-control "
                        id="inputValor"
                        name="Valor"
                        defaultValue={prod?.Valor || 0.0}
                        onBlur={(e) => handleBlurInputValor(e, prod)}
                      />
                    </div>
                    <span
                      className={`position-absolute top-100 start-50 translate-middle badge rounded-pill   ${
                        prod.Principal
                          ? "bg-primary text-white"
                          : "bg-warning text-dark"
                      } }`}
                    >
                      {prod.Nome || prod.DescricaoProduto}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="card shadow rounded mb-3">
            <div className="card-header text-white bg-dark">
              <div className="d-flex justify-content-between">
                <div className="card-title ">
                  <FontAwesomeIcon icon={faBrazilianRealSign} /> Valores
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6 col-sm-3 d-flex justify-content-center">
                  <div className="w-100">
                    <label htmlFor="inputTotalProd" className="form-label fs-5">
                      Mensalidade
                    </label>
                    <input
                      type="text"
                      id="inputTotalProd"
                      className="form-control text-white bg-secondary fs-3 text-end"
                      value={valorMensalidade.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      disabled
                    />
                  </div>
                </div>

                <div
                  className="col-6 col-sm-1 d-flex justify-content-center align-items-center position-relative"
                  style={{ top: "20px" }}
                >
                  <div className="w-100 text-center">
                    <label className="form-label fs-1">+</label>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="w-100">
                    <label
                      htmlFor="inputValorimplantacao"
                      className="form-label fs-5"
                    >
                      Implantação
                    </label>
                    <input
                      type="text"
                      className="form-control text-end fs-3"
                      id="inputValorimplantacao"
                      value={formData.ValorImplantacao || "R$ 0,00"}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        value = (parseFloat(value) / 100).toFixed(2);

                        value = `R$ ${value.replace(".", ",")}`;

                        handleChange({
                          target: { name: "ValorImplantacao", value },
                        });
                      }}
                      name="ValorImplantacao"
                    />
                  </div>
                </div>

                <div
                  className="col-6 col-sm-1 d-flex justify-content-center align-items-center position-relative"
                  style={{ top: "20px" }}
                >
                  <div className="w-100 text-center">
                    <label className="form-label fs-1">=</label>
                  </div>
                </div>

                <div className="col-6 col-sm-3 d-flex justify-content-center">
                  <div className="w-100">
                    <label htmlFor="inputTotal" className="form-label fs-5">
                      Total
                    </label>
                    <input
                      type="text"
                      id="inputTotal"
                      className="form-control text-white bg-secondary fs-3 text-end"
                      value={total.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-12 col-xl-12">
                  <label htmlFor="inputDsParecer" className="form-label fs-5">
                    Parecer*
                  </label>
                  <textarea
                    className="form-control "
                    id="inputDsParecer"
                    name="DsParecer"
                    value={formData.DsParecer || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0">
        <div className="card shadow rounded  bg-dark text-white">
          <div className=" d-flex justify-content-start m-3">
            <button
              type="submit"
              className="btn btn-success me-3"
              disabled={requisicaoEnviada}
            >
              <FontAwesomeIcon icon={faFloppyDisk} /> Salvar
            </button>
            <div className="badge bg-secondary text-wrap">
              OBS: Apos salvar aguardar a liberação do cliente
            </div>
          </div>
        </div>
      </div>

      <ModalAssociarProdutoFranquiado
        showModal={showModalAssociarProdutoFranquiado}
        handleCloseModal={handleCloseModalAssociarProdutoFranquiado}
        handleSalvarProdutoFranquiado={handleAdicionarProdutoFranquiado}
      />
    </form>
  );
};

export default NovoCliente;
