import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faList, faUser, faUsers, faCircleUser, faScrewdriverWrench, faRectangleXmark, faBox, faPlusCircle, faChartSimple, faFileInvoice, faImage, faUnlock, faPerson, faFileCirclePlus, faFile } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/rcabranca.png';
import { userUtil } from '../utils/userUtil';


const Navbar = ({ user }) => {

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <img src={logo} alt="Logo" href="/" className="navbar-brand" style={{ maxWidth: '100px', marginRight: '10px' }} />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
                            {
                                (user?.Perfil != 6) ?
                                    <>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faList} /> Visualizar
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href='/'><FontAwesomeIcon icon={faUsers} /> Clientes</a></li>
                                                {
                                                    (user?.Perfil == 1 || user?.Perfil == 3 || user?.Perfil == 4) ?
                                                        <>
                                                            <li><a className="dropdown-item" href='/indicacoes'><FontAwesomeIcon icon={faFile} /> Indicações</a></li>
                                                        </>
                                                        :
                                                        ""
                                                }
                                            </ul>
                                        </li>
                                    </>
                                    :
                                    ""
                            }
                            {
                                (user?.Perfil < 6 ) ?
                                    <>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faPlusCircle} /> Lançamento
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/novo"><FontAwesomeIcon icon={faUserPlus} /> Lançar Cliente</a></li>
                                                {/*{
                                                    (user?.Perfil == 1 || user?.Perfil == 3 || user?.Perfil == 4) ?
                                                        <>
                                                            <li><a className="dropdown-item" href='/novaindicacao'><FontAwesomeIcon icon={faFileCirclePlus} /> Lançar Indicação</a></li>
                                                        </>
                                                        :
                                                        ""
                                                }*/}
                                            </ul>
                                        </li>
                                    </>
                                    :
                                    ""
                            }
                            {
                                (user?.Perfil == 7 || user?.Perfil == 1) ?
                                    (
                                        <>
                                            <li><a className="nav-item nav-link" href="/WhiteLabel"><FontAwesomeIcon icon={faImage} /> White Label</a></li>
                                        </>

                                    ) : ""
                            }

                            {
                                (user?.Perfil == 1) ?
                                    <>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faScrewdriverWrench} /> Menu Administrativo
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/autorizacao"><FontAwesomeIcon icon={faUsers} /> Autorizar Venda</a></li>
                                                <li><a className="dropdown-item" href="/franquiados"><FontAwesomeIcon icon={faCircleUser} /> Franqueados</a></li>
                                                <li><a className="dropdown-item" href="/produtos"><FontAwesomeIcon icon={faBox} /> Produtos</a></li>
                                                <li><a className="dropdown-item" href="/questionario"><FontAwesomeIcon icon={faPlusCircle} /> Cadastro de Questionário</a></li>
                                            </ul>
                                        </li>
                                    </>
                                    :
                                    ""
                            }
                            {


                                (user?.Perfil == 4) ?
                                    <>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faScrewdriverWrench} /> Menu
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/autorizacao"><FontAwesomeIcon icon={faFileInvoice} /> Visualizar Vendas</a></li>
                                                {/* <li><a className="dropdown-item" href="/franquiados"><FontAwesomeIcon icon={faCircleUser} /> Franqueados</a></li> */}
                                                {/* <li><a className="dropdown-item" href="/produtos"><FontAwesomeIcon icon={faBox} /> Produtos</a></li> */}
                                                {/* <li><a className="dropdown-item" href="/questionario"><FontAwesomeIcon icon={faPlusCircle} /> Cadastro de Questionário</a></li> */}
                                            </ul>
                                        </li>
                                    </>
                                    :
                                    ""
                            }
                        </ul>
                        <div className="d-flex align-items-center me-4">
                            <div className="dropdown">
                                <button
                                    className="btn btn-dark dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <FontAwesomeIcon icon={faUser} /> {user.NomeUsuario?.toUpperCase()}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {(user?.Perfil == 2 || user?.Perfil == 5) && (
                                        // <li style={{cursor: 'pointer'}}>
                                        //     <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalSenhaUsuario"><FontAwesomeIcon icon={faUnlock} /> Senha master</a>
                                        // </li>
                                        <li style={{ cursor: 'pointer' }}>
                                            <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalVerSenhaMaster"><FontAwesomeIcon icon={faUnlock} /> Senha master</a>
                                        </li>
                                    )}
                                    {/* <li>
                                        <a className="dropdown-item" href="/settings">Settings</a>
                                    </li> */}
                                    <li>
                                        <a className="dropdown-item text-danger" href="/logout">
                                            <FontAwesomeIcon icon={faRectangleXmark} /> Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>

            <div className="modal fade" id="modalSenhaUsuario" tabIndex="-1" aria-labelledby="modalSenhaUsuario" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="passwordModalLabel">Visualizar senha</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3" id='senhaMaster'>
                                <label className="form-label"><FontAwesomeIcon icon={faUnlock} /> Senha Master</label>
                                <input type="text" className="form-control" id="senhaMasterInput" placeholder="Digite a senha master" />
                            </div>
                            <div className='mb-3 d-none' id='infoUsuario'>
                                <div className="mb-3">
                                    <label className="form-label"><FontAwesomeIcon icon={faUser} /> Usuário</label>
                                    <input type="text" className="form-control" id='nomeUsuario' disabled />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label"><FontAwesomeIcon icon={faUnlock} /> Senha</label>
                                    <input type="text" className="form-control" id="senhaUsuario" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" id='footerModalSenhaMaster'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" id='btnConfirmarSenha' onClick={() => userUtil.validarSenhaMaster()}>Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalVerSenhaMaster" tabIndex="-1" aria-labelledby="modalVerSenhaMaster" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="passwordModalLabel">Visualizar senha master</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3" id='informarCodigoParaVerSenhaMaster'>
                                <label className="form-label"><FontAwesomeIcon icon={faUnlock} /> CNPJ</label>
                                <input type="text" className="form-control" id="cnpjInformar" placeholder="Digite a senha master" />
                            </div>
                            <div className='mb-3 d-none' id='divSenhaMaster'>
                                <div className="mb-3">
                                    <label className="form-label"><FontAwesomeIcon icon={faUnlock} /> Senha master</label>
                                    <input type="text" className="form-control" id="senhaMasterParaVisualizar" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" id='footerModalSenhaMaster'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" id='btnConfirmarSenha' onClick={() => userUtil.verSenhaMaster()}>Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
