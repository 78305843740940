import React, { useState } from 'react';
import logo from '../images/favicon.png';
import InstallPWAButton from '../components/InstallPWAButton';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const LoginForm = ({ onLogin, storeLastVisitedURL }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataEnvio = {
            NomeUsuario: username,
            Senha: password
        };

        const queryParams = new URLSearchParams(dataEnvio).toString();
        const response = await fetch(`https://api.erp.rcasistemas.com.br/franquia/GetUsuario?${queryParams}`, {
            //const response = await fetch(`http://localhost:63299/franquia/GetUsuario?${queryParams}`, {
            //method: 'GET',
            //headers: {
            //    'Content-Type': 'application/json'
            //},
        });

        if (response.ok) {
            const data = await response.json();
            //console.log(data);
            onLogin(data);
        } else {
            alert('Credenciais inválidas. Tente novamente.');
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="d-flex justify-content-center mb-5">
                    <img src={logo} alt="Logo" href="/" className="navbar-brand" style={{ maxWidth: '100px', marginRight: '10px' }} />
                </div>
                <div className="col-md-4">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Usuário:</label>
                            <input
                                type="text"
                                id="username"
                                className="form-control"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Senha:</label>
                            <input
                                type="password"
                                id="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary d-flex align-items-center">
                                <FontAwesomeIcon icon={faDoorOpen} className="me-2" />
                                Entrar
                            </button>
                        </div>

                    </form>

                    <div className="d-flex justify-content-center">
                        <InstallPWAButton />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
