import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHotel,
  faLocationDot,
  faAddressBook,
  faBoxOpen,
  faTrash,
  faDoorClosed,
  faUser,
  faCirclePlus,
  faBrazilianRealSign,
  faPencil,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import ModalAssociarProdutoFranquiado from "../components/ModalAssociarProdutoFranquiado";
import InputMask from "react-input-mask";
import { removerAspas, validarEmail, validarTexto } from "../utils/Validadores";
import { requestOptionsPOST, requestOptionsPUT } from "../utils/RequestOptions";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const ModalEditarCliente = ({
  showModal,
  handleCloseModal,
  clienteEditar,
  usuarioLogado = null,
  editandoVendaEmAberto = false,
}) => {
  const [dadosValidos, setDadosValidos] = useState(false);

  const handleInputChange = (e, field) => {
    setclienteSelecionado({
      ...clienteSelecionado,
      [field]: e.target.value,
    });
  };

  useEffect(() => {
    if (clienteEditar != null) {
      if (editandoVendaEmAberto && clienteEditar?.Status != 0) {
        toast.warn("Só é possível editar vendas em aberto");

        showModal = false;
      }
    }
  }, [clienteEditar]);

  const handleInputChangeProdutos = (e, field, idx) => {
    const updatedProdutos = [...produtosCliente];
    const updatedValue = e.target.value;

    if (field === "VlProduto") {
      updatedProdutos[idx] = {
        ...updatedProdutos[idx],
        VlProduto: parseFloat(updatedValue.replace(",", ".")),
      };
    }

    setprodutosCliente(updatedProdutos);
  };

  const handleCrtChange = (e) => {
    const novoCrt = e.target.value;

    setclienteSelecionado((prev) => ({
      ...prev,
      CRT: novoCrt,
      Enquadramento: "",
    }));
  };

  const [
    showModalAssociarProdutoFranquiado,
    setShowModalAssociarProdutoFranquiado,
  ] = useState(false);

  const [clienteSelecionado, setclienteSelecionado] = useState({
    CdCPF_CGC: "",
    DataAlteracao: "",
    DataCadastro: "",
    NmCurto: "",
    NmLogradouro: "",
    NrInscricaoEstadual: "",
    NmPessoa: "",
    DsContatoTelefone: "",
    DsContatoTelefone2: "",
    NmUsuario: "",
    NomeBairro: "",
    NomeCidade: "",
    Objeto: "",
    Observacao: "",
    Status: -1 /*ENUM*/,
    UF: "",
    idAutorizacaoCliente: "",
    idUsuarioFranquia: "",
    CRT: "",
    Enquadramento: "",
  });

  const [clienteSelecionadoValidations, setClienteSelecionadoValidation] =
    useState({
      erroCdCPF_CGC: "",
      erroNmCurto: "",
      erroNmLogradouro: "",
      erroNrInscricaoEstadual: "",
      erroDsContatoNome: "",
      erroDsContatoTelefone: "",
      erroDsContatoTelefone2: "",
      erroNmPessoa: "",
      erroNomeBairro: "",
      erroNomeCidade: "",
      erroObservacao: "",
      erroStatus: -1 /*ENUM*/,
      erroUF: "",
      erroidAutorizacaoCliente: "",
      erroidUsuarioFranquia: "",
    });

  const [produtosCliente, setprodutosCliente] = useState([]);
  const [valorTotalProduto, setvalorTotalProduto] = useState(0.0);

  const handleBlurInputValor = async (e, selecionado) => {
    const { name, defaultValue } = e.target;

    selecionado.VlProduto = parseFloat(defaultValue.replace(",", "."));
    if (isNaN(selecionado.VlProduto)) {
      selecionado.VlProduto = 0;
    }
  };

  const handleShowModalAssociarProdutoFranquiado = () => {
    setShowModalAssociarProdutoFranquiado(true);
  };

  const handleCloseModalAssociarProdutoFranquiado = () => {
    setShowModalAssociarProdutoFranquiado(false);
  };

  const salvarCliente = async () => {
    if (!dadosValidos) {
      return;
    }

    clienteSelecionado.idAutorizacaoCliente =
      clienteEditar?.idAutorizacaoCliente;
    clienteSelecionado.DataCadastro = clienteEditar?.DataCadastro;
    clienteSelecionado.DataAlteracao = new Date().toJSON();
    clienteSelecionado.NmPessoa = removerAspas(
      clienteSelecionado?.NmPessoa?.substring(0, 50)
    );
    clienteSelecionado.logradouro = removerAspas(
      clienteSelecionado?.logradouro?.substring(0, 50)
    );
    clienteSelecionado.CdCEP = removerAspas(clienteSelecionado?.CdCEP);
    clienteSelecionado.DtNascimento = null;
    clienteSelecionado.Observacao = removerAspas(clienteSelecionado?.DsParecer);
    clienteSelecionado.DsContatoEmail = removerAspas(
      clienteSelecionado?.DsContatoEmail?.toLowerCase()
    );
    clienteSelecionado.produtomodel = produtosCliente.map((produto) => {
      return {
        ...produto,
        TipoLancamento: parseInt(produto.TipoLancamento),
      };
    });

    clienteSelecionado.Objeto = JSON.stringify(clienteSelecionado);

    const response = await fetch(
      "https://api.erp.rcasistemas.com.br/franquia/PutAutorizacaoCliente",
      requestOptionsPUT(clienteSelecionado)
    );
    if (response.status == 200) {
      toast.success("Dados Atualizados com sucesso");
    } else {
      toast.error(response.text || "Erro desconhecido");
    }
  };

  const validarCliente = () => {
    let isValid = true;
    let errors = {};

    // if (!validarCNPJ(clienteSelecionado.CdCPF_CGC)) {
    //     isValid = false;
    //     errors.erroCdCPF_CGC = "CNPJ inválido";
    // }

    if (!validarTexto(clienteSelecionado.NmCurto)) {
      isValid = false;
      errors.erroNmCurto =
        "Nome fantasia inválido" + ". Deve ter entre 0 e 150 caracteres.";
    }

    if (!validarTexto(clienteSelecionado.NmPessoa)) {
      isValid = false;
      errors.erroNmPessoa =
        "Razão Social inválida" + ". Deve ter entre 0 e 150 caracteres.";
    }

    if (!validarTexto(clienteSelecionado.NmLogradouro)) {
      isValid = false;
      errors.erroNmLogradouro =
        "Endereço inválido" + ". Deve ter entre 0 e 150 caracteres.";
    }

    // if (!validarTexto(clienteSelecionado.UF)) {
    //     isValid = false;
    //     errors.erroUf = "UF inválida";
    // }

    if (!validarTexto(clienteSelecionado.NomeCidade)) {
      isValid = false;
      errors.erroNomeCidade =
        "Cidade inválida" + ". Deve ter entre 0 e 150 caracteres.";
    }

    // if (!validarCEP(clienteSelecionado.CdCEP)) {
    //     isValid = false;
    //     errors.erroCdCEP = "CEP inválido";
    // }

    if (!validarEmail(clienteSelecionado.DsContatoEmail)) {
      isValid = false;
      errors.erroDsContatoEmail = "E-mail inválido";
    }

    if (!validarTexto(clienteSelecionado.DsContatoNome)) {
      isValid = false;
      errors.erroDsContatoNome =
        "Nome do contato inválido" + ". Deve ter entre 0 e 150 caracteres.";
    }

    // if (!validarTelefone(clienteSelecionado.DsContatoTelefone)) {
    //     isValid = false;
    //     errors.erroDsContatoTelefone = "Telefone inválido";
    // }

    // if (!validarTelefone(clienteSelecionado.DsContatoTelefone2)) {
    //     isValid = false;
    //     errors.erroDsContatoTelefone2 = "Telefone 2 inválido";
    // }

    setClienteSelecionadoValidation(errors);
    return isValid;
  };

  const handleAutorizarCliente = async (e, status) => {
    e.preventDefault();

    try {
      clienteSelecionado.NmPessoa = removerAspas(
        clienteSelecionado?.NmPessoa?.substring(0, 50)
      );
      clienteSelecionado.logradouro = removerAspas(
        clienteSelecionado?.logradouro?.substring(0, 50)
      );
      clienteSelecionado.CdCEP = removerAspas(
        clienteSelecionado?.CdCEP?.replace(".", "")
      );
      clienteSelecionado.DtNascimento = null;

      clienteSelecionado.DsParecer += removerAspas(
        "\n\n" + "Valor implantação: " + clienteSelecionado?.ValorImplantacao ??
          0
      );

      clienteSelecionado.ValorImplantacao = Number(
        clienteSelecionado?.ValorImplantacao ?? 0
      );
      clienteEditar.Status = status;
      clienteEditar.Objeto = JSON.stringify(clienteSelecionado);

      //const response = await fetch('http://localhost:63299/franquia/PostAutorizacaoCliente', requestOptions);
      const response = await fetch(
        "https://api.erp.rcasistemas.com.br/franquia/PostAutorizacaoCliente",
        requestOptionsPOST(clienteEditar)
      );

      if (!response.ok) {
        throw new Error("Erro ao enviar o formulário ");
      }

      window.location.replace("/autorizacao");
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
    }
  };

  const handleRemoverProdutoLancamento = async (produtoFranquiadoRemover) => {
    var listaProd = produtosCliente.filter(
      (objeto) => objeto.IdProduto !== produtoFranquiadoRemover.IdProduto
    );
    setprodutosCliente(listaProd);
  };

  const handleAdicionarProdutoFranquiado = async (produtoFranquiadoSalvar) => {
    if ((produtoFranquiadoSalvar.IdProduto ?? "") == "") {
      //setShowAlert(true);
      return;
    }

    setprodutosCliente([
      ...produtosCliente,
      {
        IdCaracteristicaPessoa: null,
        IdProduto: produtoFranquiadoSalvar.IdProduto,
        NmProduto: produtoFranquiadoSalvar.DescricaoProduto,
        VlProduto: produtoFranquiadoSalvar.Valor,
        TipoLancamento: 0,
      },
    ]);

    handleCloseModalAssociarProdutoFranquiado();
  };

  useEffect(() => {
    const IniciarPropriedades = async () => {
      if (clienteEditar?.Objeto == undefined && clienteEditar?.Objeto == null)
        return;

      var clienteAtu = JSON.parse(clienteEditar?.Objeto);

      setclienteSelecionado(clienteAtu);

      setprodutosCliente(clienteAtu.produtomodel);

      setvalorTotalProduto(
        clienteAtu?.produtomodel?.reduce((acc, prod) => {
          return acc + parseFloat(prod?.VlProduto ?? 0);
        }, 0) ?? 0
      );
    };

    IniciarPropriedades();
  }, [clienteEditar]);

  const exibirErroSeExitir = (campoErro) => {
    if ((campoErro?.length ?? 0) == 0) {
      return <></>;
    }
    return (
      <div className="invalid-feedback d-block" role="alert">
        {clienteSelecionadoValidations[campoErro]}
      </div>
    );
  };

  useEffect(() => {
    setDadosValidos(validarCliente());
  }, [clienteSelecionado]);

  const handleEnqChange = (e) => {
    setEnq(e.target.value);
  };

  const opcoesRegimeNormal = [
    { value: "LucroPresumido", label: "LUCRO PRESUMIDO" },
    { value: "LucroReal", label: "LUCRO REAL" },
];

const opcoesSimples = [
    { value: "ReceitaBrutaate180", label: "Até 180.000,00" },
    { value: "ReceitaBrutade180a360", label: "De 180.000,01 a 360.000,00" },
    { value: "ReceitaBrutade360a720", label: "De 360.000,01 a 720.000,00" },
    { value: "ReceitaBrutade720a1800", label: "De 720.000,01 a 1.800.000,00" },
    { value: "ReceitaBrutade1800a3600", label: "De 1.800.000,01 a 3.600.000,00" },
    { value: "ReceitaBrutade36000a4800", label: "De 3.600.000,01 a 4.800.000,00" },
];


  return (
    <div>
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header text-white bg-dark">
                <h5 className="modal-title">
                  <FontAwesomeIcon icon={faUser} /> Editar cliente
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={handleCloseModal}
                >
                  {/*<span aria-hidden="true">&times;</span>*/}
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <div className="card shadow mb-3">
                      <div className="card-header text-white bg-dark">
                        <FontAwesomeIcon icon={faHotel} /> Dados Empresa
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 mb-2">
                            <label htmlFor="inputRazao" className="form-label">
                              Razão Social
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              maxLength={150}
                              id="inputRazao"
                              value={
                                clienteSelecionado.NmPessoa?.toUpperCase() || ""
                              }
                              onChange={(e) => handleInputChange(e, "NmPessoa")}
                            />
                            {exibirErroSeExitir("erroNmPessoa")}
                          </div>
                          <div className="col-12 mb-2">
                            <label
                              htmlFor="inputFantasia"
                              className="form-label"
                            >
                              Nome Fantasia
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              maxLength={150}
                              id="inputFantasia"
                              value={
                                clienteSelecionado?.NmCurto?.toUpperCase() || ""
                              }
                              onChange={(e) => handleInputChange(e, "NmCurto")}
                            />
                            {exibirErroSeExitir("erroNmCurto")}
                          </div>
                          <div className="col-12 col-sm-5  mb-2">
                            <label htmlFor="inputCnpj" className="form-label">
                              Cnpj
                            </label>
                            <InputMask
                              mask="99.999.999/9999-99"
                              type="text"
                              className="form-control "
                              id="inputCnpj"
                              value={clienteSelecionado.CdCPF_CGC || ""}
                              onChange={(e) =>
                                handleInputChange(e, "CdCPF_CGC")
                              }
                            />
                            {exibirErroSeExitir("erroCdCPF_CGC")}
                          </div>
                          <div className="col-6 col-sm-4 mb-2">
                            <label htmlFor="inputIe" className="form-label">
                              IE
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              id="inputIe"
                              value={
                                clienteSelecionado.NrInscricaoEstadual?.toUpperCase() ||
                                ""
                              }
                              onChange={(e) =>
                                handleInputChange(e, "NrInscricaoEstadual")
                              }
                            />
                            {exibirErroSeExitir("erroNrInscricaoEstadual")}
                          </div>
                          <div className="col-6 col-sm-3 mb-2">
                            <label htmlFor="inputIm" className="form-label">
                              IM
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              id="inputIm"
                              value={
                                clienteSelecionado.NrInscricaoMunicipal?.toUpperCase() ||
                                ""
                              }
                              onChange={(e) =>
                                handleInputChange(e, "NrInscricaoMunicipal")
                              }
                            />
                            {exibirErroSeExitir("erroNrInscricaoMunicipal")}
                          </div>

                          <div className="col-12 col-xl-6">
                            <label htmlFor="selectCrt" className="form-label">
                              CRT (Cód. de regime tributário)*
                            </label>
                            <select
                              className="form-select"
                              name="selectCrt"
                              id="selectCrt"
                              value={clienteSelecionado.CRT}
                              onChange={handleCrtChange}
                            >
                              <option value="">Selecione</option>
                              <option value="SimplesNacional">
                                Simples Nacional
                              </option>
                              <option value="SimplesNacionalExcessoSublimite">
                                Simples Nacional – excesso de sublimite de
                                receita bruta
                              </option>
                              <option value="RegimeNormal">
                                Regime Normal
                              </option>
                            </select>
                          </div>

                          <div className="col-12 col-xl-6">
                            <label htmlFor="selectEnq" className="form-label">
                              Faixa Enquadramento
                            </label>
                            <select
                              className="form-select"
                              name="selectEnq"
                              id="selectEnq"
                              value={clienteSelecionado.Enquadramento}
                              onChange={(e) =>
                                handleInputChange(e, "Enquadramento")
                              }
                            >
                              <option value="">Selecione</option>
                              {clienteSelecionado.CRT === "RegimeNormal"
                                ? opcoesRegimeNormal.map((op) => (
                                    <option key={op.value} value={op.value}>
                                      {op.label}
                                    </option>
                                  ))
                                : opcoesSimples.map((op) => (
                                    <option key={op.value} value={op.value}>
                                      {op.label}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className="card shadow mb-3">
                      <div className="card-header text-white bg-dark">
                        <FontAwesomeIcon icon={faLocationDot} /> Endereço
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-2">
                            <label
                              htmlFor="inputEndereco"
                              className="form-label"
                            >
                              Endereço
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              maxLength={150}
                              id="inputEndereco"
                              value={clienteSelecionado?.NmLogradouro || ""}
                              onChange={(e) =>
                                handleInputChange(e, "NmLogradouro")
                              }
                            />
                            {exibirErroSeExitir("erroNmLogradouro")}
                          </div>
                          <div className="col-12 col-sm-6 mb-2">
                            <label htmlFor="inputCidade" className="form-label">
                              Cidade
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              maxLength={150}
                              id="inputCidade"
                              value={clienteSelecionado?.NomeCidade || ""}
                              onChange={(e) =>
                                handleInputChange(e, "NomeCidade")
                              }
                            />
                            {exibirErroSeExitir("erroNomeCidade")}
                          </div>
                          <div className="col-5 col-sm-3 mb-2">
                            <label htmlFor="inputCep" className="form-label">
                              Cep
                            </label>
                            <InputMask
                              mask="99999-999"
                              type="text"
                              className="form-control "
                              id="inputCep"
                              value={clienteSelecionado?.CdCEP || ""}
                              onChange={(e) => handleInputChange(e, "CdCEP")}
                            />
                            {exibirErroSeExitir("erroCdCEP")}
                          </div>
                          <div className="col-3 col-sm-2 mb-2">
                            <label htmlFor="inputUf" className="form-label">
                              Uf
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={2}
                              id="inputUf"
                              value={clienteSelecionado?.IdUF || ""}
                              onChange={(e) => handleInputChange(e, "IdUF")}
                            />
                            {exibirErroSeExitir("erroUf")}
                          </div>
                          <div className="col-5 col-sm-4 mb-2">
                            <label htmlFor="inputBairro" className="form-label">
                              Bairro
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputBairro"
                              maxLength={150}
                              value={clienteSelecionado?.NomeBairro || ""}
                              onChange={(e) =>
                                handleInputChange(e, "NomeBairro")
                              }
                            />
                            {exibirErroSeExitir("erroNomeBairro")}
                          </div>
                          <div className="col-5 col-sm-3 mb-2">
                            <label htmlFor="inputNumero" className="form-label">
                              Nº
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputNumero"
                              value={clienteSelecionado?.NrLogradouro || ""}
                              onChange={(e) =>
                                handleInputChange(e, "NrLogradouro")
                              }
                            />
                            {exibirErroSeExitir("erroNrLogradouro")}
                          </div>
                          <div className="col-12 mb-2">
                            <label
                              htmlFor="inputComplemento"
                              className="form-label"
                            >
                              Complemento
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputComplemento"
                              maxLength={150}
                              value={clienteSelecionado?.DsComplemento || ""}
                              onChange={(e) =>
                                handleInputChange(e, "DsComplemento")
                              }
                            />
                            {exibirErroSeExitir("erroDsComplemento")}
                          </div>
                          {/*<div className="col-12">*/}
                          {/*    <label htmlFor="inputParecer" className="form-label">Parecer</label>*/}
                          {/*    <input type="text" className="form-control" id="inputParecer" value={clienteSelecionado?.DsParecer?.trim() || ''} />*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className="card shadow mb-3">
                      <div className="card-header text-white bg-dark">
                        <FontAwesomeIcon icon={faAddressBook} /> Contato
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-2">
                            <label
                              htmlFor="inputContato"
                              className="form-label"
                            >
                              Contato
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              id="inputContato"
                              maxLength={150}
                              value={clienteSelecionado?.DsContatoNome || ""}
                              onChange={(e) =>
                                handleInputChange(e, "DsContatoNome")
                              }
                            />
                            {exibirErroSeExitir("erroDsContatoNome")}
                          </div>
                          <div className="col-12 col-sm-6 mb-2">
                            <label
                              htmlFor="inputTelefone"
                              className="form-label"
                            >
                              Telefone 1
                            </label>
                            <InputMask
                              mask="(99) 99999 9999"
                              type="text"
                              className="form-control "
                              id="inputTelefone"
                              value={
                                clienteSelecionado?.DsContatoTelefone || ""
                              }
                              onChange={(e) =>
                                handleInputChange(e, "DsContatoTelefone")
                              }
                            />
                            {exibirErroSeExitir("erroDsContatoTelefone")}
                          </div>
                          <div className="col-12 col-sm-6 mb-2">
                            <label
                              htmlFor="inputTelefone2"
                              className="form-label"
                            >
                              Telefone 2
                            </label>
                            <InputMask
                              mask="(99) 99999 9999"
                              type="text"
                              className="form-control "
                              id="inputTelefone2"
                              value={
                                clienteSelecionado?.DsContatoTelefone2 || ""
                              }
                              onChange={(e) =>
                                handleInputChange(e, "DsContatoTelefone2")
                              }
                            />
                            {exibirErroSeExitir("erroDsContatoTelefone2")}
                          </div>
                          <div className="col-12 col-sm-6 mb-2">
                            <label htmlFor="inputemail" className="form-label">
                              E-Mail
                            </label>
                            <input
                              type="email"
                              className="form-control "
                              id="inputemail"
                              value={
                                clienteSelecionado?.DsContatoEmail?.trim()?.toLowerCase() ||
                                ""
                              }
                              onChange={(e) =>
                                handleInputChange(e, "DsContatoEmail")
                              }
                            />
                            {exibirErroSeExitir("erroDsContatoEmail")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className="card shadow rounded mb-3">
                      <div className="card-header text-white bg-dark">
                        <div className="d-flex justify-content-between">
                          <div className="card-title ">
                            <FontAwesomeIcon icon={faBoxOpen} /> Mensalidade
                          </div>
                          <div
                            className="btn btn-toolbar btn-dark"
                            onClick={handleShowModalAssociarProdutoFranquiado}
                          >
                            <FontAwesomeIcon icon={faCirclePlus} />
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-body"
                        style={{ height: "170px", overflow: "auto" }}
                      >
                        <div className="table-responsive rounded">
                          <table className="table">
                            <tbody>
                              {produtosCliente != null ? (
                                produtosCliente.map((produto, idx) => (
                                  <tr
                                    key={
                                      produto.IdProduto + produto.TipoLancamento
                                    }
                                  >
                                    <td className="text-truncate">
                                      {produto.NmProduto}
                                    </td>
                                    <td className="text-truncate">
                                      <div className="input-group">
                                        <span className="input-group-text">
                                          R$
                                        </span>
                                        <NumericFormat
                                          value={parseFloat(
                                            produto?.VlProduto ?? 0.0
                                          )}
                                          onBlur={(e) =>
                                            handleBlurInputValor(e, produto)
                                          }
                                          onValueChange={(values) => {
                                            const { value } = values;
                                            handleInputChangeProdutos(
                                              { target: { value } },
                                              "VlProduto",
                                              idx
                                            );
                                          }}
                                          decimalSeparator={","}
                                          // prefix={'R$'}
                                          allowNegative={false}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          className="form-control"
                                        />
                                      </div>
                                    </td>
                                    <td className="text-truncate">
                                      <div>
                                        <a
                                          className={`btn btn-light`}
                                          onClick={() =>
                                            handleRemoverProdutoLancamento(
                                              produto
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className="card shadow rounded mb-3">
                      <div className="card-header text-white bg-dark">
                        <div className="d-flex justify-content-between">
                          <div className="card-title ">
                            <FontAwesomeIcon icon={faBrazilianRealSign} />{" "}
                            Valores
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6 col-md-5">
                            <label
                              htmlFor="inputTotalProd"
                              className="form-label"
                            >
                              Mensalidade
                            </label>
                            <div className="input-group">
                              <label className="form-label">
                                {valorTotalProduto.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </label>
                              {/*<span className="input-group-text" id="basic-addon1">R$</span>*/}
                              {/*<input type="number" className="form-control " id="inputTotalProd" name="TotalProd" defaultValue={produtosFranquiado.reduce((acc, prod) => { return acc + parseFloat(prod?.Valor ?? 0) }, 0)} />*/}
                            </div>
                          </div>

                          <div className="col-6 col-xl-3">
                            <label
                              htmlFor="inputTotalProd"
                              className="form-label"
                            >
                              Implantação
                            </label>
                            <input
                              type="number"
                              mask="0.00"
                              className="form-control "
                              id="inputValorimplantacao"
                              value={Number(
                                clienteSelecionado.ValorImplantacao
                              )?.toFixed(2)}
                              onChange={(e) =>
                                handleInputChange(e, "ValorImplantacao")
                              }
                              name="ValorImplantacao"
                            />
                          </div>

                          <div className="col-12 col-xl-12">
                            <label
                              htmlFor="inputDsParecer"
                              className="form-label"
                            >
                              Parecer
                            </label>
                            <textarea
                              className="form-control "
                              id="inputDsParecer"
                              name="DsParecer"
                              value={clienteSelecionado?.DsParecer || ""}
                              onChange={(e) =>
                                handleInputChange(e, "DsParecer")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {clienteEditar.Status == 0 && usuarioLogado?.Perfil != 4 ? (
                  <>
                    {!editandoVendaEmAberto && (
                      <>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={(e) => handleAutorizarCliente(e, 1)}
                        >
                          <FontAwesomeIcon icon={faCheck} /> Autorizar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(e) => handleAutorizarCliente(e, 2)}
                        >
                          <FontAwesomeIcon icon={faTrash} /> Rejeitar
                        </button>
                      </>
                    )}
                    <button
                      disabled={!dadosValidos}
                      type="button"
                      className="btn btn-primary"
                      onClick={() => salvarCliente()}
                    >
                      <FontAwesomeIcon icon={faFloppyDisk} /> Salvar
                    </button>
                  </>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  <FontAwesomeIcon icon={faDoorClosed} /> Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalAssociarProdutoFranquiado
        showModal={showModalAssociarProdutoFranquiado}
        handleCloseModal={handleCloseModalAssociarProdutoFranquiado}
        handleSalvarProdutoFranquiado={handleAdicionarProdutoFranquiado}
      />
    </div>
  );
};

export default ModalEditarCliente;
