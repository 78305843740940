import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardUser, faHotel, faFilter, faUser, faFileExcel, faFileLines } from '@fortawesome/free-solid-svg-icons';
import ModalDetalheClienteIndicado from '../components/ModalDetalheClienteIndicado'
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Indicacoes = ({ user }) => {
    const [formData, setFormData] = useState({ NmPessoa: '', DsContatoEmail: '', Observacao: '' });
    const [clientes, setClientes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [campoHabilitado, setCampoHabilitado] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [clientesFiltrado, setClientesFiltrado] = useState([]);
    const [filtroIndicacao, setFiltroIndicacao] = useState({ cliente: '', vendedor: '', indicado: '', status: null });
    const [statusSelecionado, setStatusSelecionado] = useState("Todos");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [clienteSelecionada, setclienteSelecionada] = useState(null);
    const [franquiados, setfranquiados] = useState([]);
    const itensPorPagina = 13;

    const handleChangeIndicado = (e) => {
        const { name, value } = e.target;
        setFiltroIndicacao(f => ({ ...f, [name]: value.toUpperCase() }));
    };

    const handleChangeStatus = (event) => {
        const statusSelecionado = event.target.value;

        const statusMap = {
            "Pendente": 0,
            "Contratado": 1,
            "Cancelado": 2,
            "EmAndamento": 3
        };

        const statusNumber = statusSelecionado === "Todos" ? null : statusMap[statusSelecionado];

        setFiltroIndicacao(prevFiltro => ({
            ...prevFiltro,
            status: statusNumber
        }));
        setStatusSelecionado(statusSelecionado);
    };

    const handleCloseModalIndicado = () => {
        setShowModal(false);
    };

    const handleShowModalIndicado = (clientesel) => {
        setclienteSelecionada(clientesel);
        setShowModal(true);
    };

    const exportToExcel = () => {
        if (clientesFiltrado.length === 0) {
            alert("Nenhum dado disponível para exportação.");
            return;
        }

        const ws = XLSX.utils.json_to_sheet(
            clientesFiltrado.map(cliente => ({
                "Código": cliente?.Cliente?.codigo?.trim() || "",
                "Razão Social": cliente?.Cliente?.cliente?.trim() || "",
                "CNPJ": cliente?.Cliente?.cnpj?.trim() || "",
                "Cidade": cliente?.Cliente?.cidade?.trim() || "",
                "UF": cliente?.Cliente?.Uf?.trim() || "",
                "Vendedor": cliente?.Cliente?.vendedor?.trim() || "",
                "Indicação": cliente?.Cliente?.indicado?.trim() || "",
                "Valor (R$)": cliente?.Cliente?.Valor
                    ? new Intl.NumberFormat("pt-BR", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(cliente.Cliente.Valor)
                    : "0,00",
                "Status": cliente?.Cliente?.status === 0
                    ? "Pendente"
                    : cliente?.Cliente?.status === 1
                        ? "Contratado"
                        : cliente?.Cliente?.status === 2
                            ? "Não Fechado"
                            : cliente?.Cliente?.status === 3 
                                ? "Em Andamento"
                                : "Desconhecido",
                "Data Indicação": cliente?.Cliente?.DataIndicacao
                    ? new Date(cliente.Cliente.DataIndicacao).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    })
                    : "",
                "Data Previsão": cliente?.Cliente?.DataPrevisao
                    ? new Date(cliente.Cliente.DataPrevisao).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    })
                    : "",
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Indicações");
        XLSX.writeFile(wb, "indicacoes.xlsx");
    };

    const exportToTxt = () => {
        if (clientesFiltrado.length === 0) {
            alert("Nenhum dado disponível para exportação.");
            return;
        }

        let txtContent = "Código | Razão Social | CNPJ | Cidade | UF | Vendedor | Indicação | Valor (R$) | Status | Data Indicação\n";
        txtContent += "-".repeat(120) + "\n";

        clientesFiltrado.forEach(cliente => {
            txtContent += `${cliente?.Cliente?.codigo?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.cliente?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.cnpj?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.cidade?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.Uf?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.vendedor?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.indicado?.trim() || ""} | `;
            txtContent += `${cliente?.Cliente?.Valor
                ? new Intl.NumberFormat("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cliente.Cliente.Valor)
                : "0,00"
                } | `;
            txtContent += `${cliente?.Cliente?.status === 0
                ? "Pendente"
                : cliente?.Cliente?.status === 1
                    ? "Contratado"
                    : cliente?.Cliente?.status === 2
                        ? "Não Fechado"
                        : cliente?.Cliente?.status === 3 
                            ? "Em Andamento"
                                : "Desconhecido"
                } | `;
            txtContent += `${cliente?.Cliente?.DataIndicacao
                ? new Date(cliente.Cliente.DataIndicacao).toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" })
                : ""
                } | `;
            txtContent += `${cliente?.Cliente?.DataPrevisao
                ? new Date(cliente.Cliente.DataPrevisao).toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" })
                : ""
                }\n`;
        });

        const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "indicacoes.txt");
    };

    if (user?.Perfil == 1 || user?.Perfil == 4) {

        useEffect(() => {
            const fetchClientes = async () => {
                if (user?.idCaracteristica == null) return;

                setClientes(null);

                var requestOptions = {
                    method: 'GET',
                    headers: { "Content-Type": 'application/json' }
                };

                const idUsuarioFranquiaIndicado = "";
                const idUsuarioFranquia = "";
                const Status = "";

                const url = new URL("https://api.erp.rcasistemas.com.br/franquia/GetIndicacao");
                url.searchParams.append("idUsuarioFranquiaIndicado", idUsuarioFranquiaIndicado);
                url.searchParams.append("idUsuarioFranquia", idUsuarioFranquia);
                url.searchParams.append("Status", Status);

                const response = await fetch(url, requestOptions);

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }

                const data = await response.json();

                setClientes(data);
                setClientesFiltrado(data);
            };

            fetchClientes();
        }, [searchTerm, user]);

        useEffect(() => {
            if (!clientes) return;

            const clientesFiltrados = clientes
                .filter(cli => {
                    const cliente = cli?.Cliente?.cliente?.toUpperCase() || "";
                    const fantasia = cli?.Cliente?.fantasia?.toUpperCase() || "";
                    const codigo = cli?.Cliente?.codigo?.toUpperCase() || "";
                    const vendedor = cli?.Cliente?.vendedor?.toUpperCase() || "";
                    const indicado = cli?.Cliente?.indicado?.toUpperCase() || "";
                    const status = cli?.Cliente?.status;

                    const matchCliente = (!filtroIndicacao.cliente || cliente.includes(filtroIndicacao.cliente) || fantasia.includes(filtroIndicacao.cliente) || codigo.includes(filtroIndicacao.cliente));
                    const matchVendedor = !filtroIndicacao.vendedor || vendedor.includes(filtroIndicacao.vendedor);
                    const matchIndicado = !filtroIndicacao.indicado || indicado.includes(filtroIndicacao.indicado);
                    const matchStatus = filtroIndicacao.status === null || status === filtroIndicacao.status;

                    return matchCliente && matchVendedor && matchIndicado && matchStatus;
                })
                .sort((a, b) => {
                    const dateA = a?.Cliente?.DataIndicacao ? new Date(a.Cliente?.DataIndicacao) : null;
                    const dateB = b?.Cliente?.DataIndicacao ? new Date(b.Cliente?.DataIndicacao) : null;

                    if (dateA === null && dateB !== null) return 1;
                    if (dateB === null && dateA !== null) return -1;
                    if (dateA !== null && dateB !== null) {
                        const dateComparison = dateB - dateA;
                        if (dateComparison !== 0) return dateComparison;
                    }

                    return (a?.Cliente?.cliente || "").localeCompare(b?.Cliente?.cliente || "");
                });

            setClientesFiltrado(clientesFiltrados);
            setPaginaAtual(1);
        }, [filtroIndicacao, clientes]);
    }
    else if (user?.Perfil == 6) {

        useEffect(() => {
            const fetchClientes = async () => {
                if (user?.idCaracteristica == null) return;

                setClientes(null);

                var requestOptions = {
                    method: 'GET',
                    headers: { "Content-Type": 'application/json' }
                };

                const idUsuarioFranquiaIndicado = user?.idUsuarioFranquia;
                const idUsuarioFranquia = "";
                const Status = "";

                const url = new URL("https://api.erp.rcasistemas.com.br/franquia/GetIndicacao");
                url.searchParams.append("idUsuarioFranquiaIndicado", idUsuarioFranquiaIndicado);
                url.searchParams.append("idUsuarioFranquia", idUsuarioFranquia);
                url.searchParams.append("Status", Status);

                const response = await fetch(url, requestOptions);

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }

                const data = await response.json();

                setClientes(data);
                setClientesFiltrado(data);
            };

            fetchClientes();
        }, [searchTerm, user]);

        useEffect(() => {
            if (!clientes) return;

            const clientesFiltrados = clientes
                .filter(cli => {
                    const cliente = cli?.Cliente?.cliente?.toUpperCase() || "";
                    const fantasia = cli?.Cliente?.fantasia?.toUpperCase() || "";
                    const codigo = cli?.Cliente?.codigo?.toUpperCase() || "";
                    const vendedor = cli?.Cliente?.vendedor?.toUpperCase() || "";
                    const indicado = cli?.Cliente?.indicado?.toUpperCase() || "";
                    const status = cli?.Cliente?.status;

                    const matchCliente = (!filtroIndicacao.cliente || cliente.includes(filtroIndicacao.cliente) || fantasia.includes(filtroIndicacao.cliente) || codigo.includes(filtroIndicacao.cliente));
                    const matchVendedor = !filtroIndicacao.vendedor || vendedor.includes(filtroIndicacao.vendedor);
                    const matchIndicado = !filtroIndicacao.indicado || indicado.includes(filtroIndicacao.indicado);
                    const matchStatus = filtroIndicacao.status === null || status === filtroIndicacao.status;

                    return matchCliente && matchVendedor && matchIndicado && matchStatus;
                })
                .sort((a, b) => {
                    const dateA = a?.Cliente?.DataIndicacao ? new Date(a.Cliente?.DataIndicacao) : null;
                    const dateB = b?.Cliente?.DataIndicacao ? new Date(b.Cliente?.DataIndicacao) : null;

                    if (dateA === null && dateB !== null) return 1;
                    if (dateB === null && dateA !== null) return -1;
                    if (dateA !== null && dateB !== null) {
                        const dateComparison = dateB - dateA;
                        if (dateComparison !== 0) return dateComparison;
                    }

                    return (a?.Cliente?.cliente || "").localeCompare(b?.Cliente?.cliente || "");
                });

            setClientesFiltrado(clientesFiltrados);
            setPaginaAtual(1);
        }, [filtroIndicacao, clientes]);

        /*useEffect(() => {
            const fetchFranquiados = async () => {
                try {
                    const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetFranquiados');
                    
                    if (!response.ok) {
                        throw new Error('Erro ao carregar franquiados');
                    }
        
                    const data = await response.json();
                    console.log(data);
                    setfranquiados(data);
                } catch (error) {
                    console.error('Erro ao carregar franquiados:', error);
                }
            };
        
            fetchFranquiados();
        }, [user]);*/

    }
    else {

        useEffect(() => {
            const fetchClientes = async () => {
                if (user?.idCaracteristica == null) return;

                setClientes(null);

                var requestOptions = {
                    method: 'GET',
                    headers: { "Content-Type": 'application/json' }
                };

                const idUsuarioFranquiaIndicado = "";
                const idUsuarioFranquia = user?.idUsuarioFranquia;
                const Status = "";

                const url = new URL("https://api.erp.rcasistemas.com.br/franquia/GetIndicacao");
                url.searchParams.append("idUsuarioFranquiaIndicado", idUsuarioFranquiaIndicado);
                url.searchParams.append("idUsuarioFranquia", idUsuarioFranquia);
                url.searchParams.append("Status", Status);

                const response = await fetch(url, requestOptions);

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }

                const data = await response.json();

                setClientes(data);
                setClientesFiltrado(data);
            };

            fetchClientes();
        }, [searchTerm, user]);

        useEffect(() => {
            if (!clientes) return;

            const clientesFiltrados = clientes
                .filter(cli => {
                    const cliente = cli?.Cliente?.cliente?.toUpperCase() || "";
                    const fantasia = cli?.Cliente?.fantasia?.toUpperCase() || "";
                    const codigo = cli?.Cliente?.codigo?.toUpperCase() || "";
                    const vendedor = cli?.Cliente?.vendedor?.toUpperCase() || "";
                    const indicado = cli?.Cliente?.indicado?.toUpperCase() || "";
                    const status = cli?.Cliente?.status;

                    const matchCliente = (!filtroIndicacao.cliente || cliente.includes(filtroIndicacao.cliente) || fantasia.includes(filtroIndicacao.cliente) || codigo.includes(filtroIndicacao.cliente));
                    const matchVendedor = !filtroIndicacao.vendedor || vendedor.includes(filtroIndicacao.vendedor);
                    const matchIndicado = !filtroIndicacao.indicado || indicado.includes(filtroIndicacao.indicado);
                    const matchStatus = filtroIndicacao.status === null || status === filtroIndicacao.status;

                    return matchCliente && matchVendedor && matchIndicado && matchStatus;
                })
                .sort((a, b) => {
                    const dateA = a?.Cliente?.DataIndicacao ? new Date(a.Cliente?.DataIndicacao) : null;
                    const dateB = b?.Cliente?.DataIndicacao ? new Date(b.Cliente?.DataIndicacao) : null;

                    if (dateA === null && dateB !== null) return 1;
                    if (dateB === null && dateA !== null) return -1;
                    if (dateA !== null && dateB !== null) {
                        const dateComparison = dateB - dateA;
                        if (dateComparison !== 0) return dateComparison;
                    }

                    return (a?.Cliente?.cliente || "").localeCompare(b?.Cliente?.cliente || "");
                });

            setClientesFiltrado(clientesFiltrados);
            setPaginaAtual(1);
        }, [filtroIndicacao, clientes]);
    }

    const indiceUltimoItem = paginaAtual * itensPorPagina;
    const indicePrimeiroItem = indiceUltimoItem - itensPorPagina;
    const clientesPagina = clientesFiltrado.slice(indicePrimeiroItem, indiceUltimoItem);
    const totalPaginas = Math.ceil(clientesFiltrado.length / itensPorPagina);


    return (
        <div>
            <div className="card-deck">
                <div className="card mb-1 shadow-lg rounded bg-dark">
                    <div className="card-header p-3">
                        <div className="row text-white">
                            <div className="col-12 col-md-4">
                                <label htmlFor="inputCliente" className="form-label"><FontAwesomeIcon icon={faClipboardUser} /> Cliente</label>
                                <input placeholder='Nome do cliente' type="text" className="form-control" id="inputCliente" name="cliente" value={filtroIndicacao.cliente || ""} onChange={handleChangeIndicado} />
                            </div>

                            {
                                (user?.Perfil != 6) && (
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="inputIndicado" className="form-label"><FontAwesomeIcon icon={faHotel} /> Indicação</label>
                                        <input placeholder='Nome do indicado' type="text" className="form-control" id="inputIndicado" name="indicado" value={filtroIndicacao.indicado || ""} onChange={handleChangeIndicado} />
                                    </div>
                                )
                            }

                            {
                                (user?.Perfil != 3 && user?.Perfil != 6) && (
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faUser} /> Vendedor</label>
                                        <input placeholder='Nome do vendedor' type="text" className="form-control" id="inputVendedor" name="vendedor" value={filtroIndicacao.vendedor || ""} onChange={handleChangeIndicado} />
                                    </div>
                                )
                            }

                            <div className="col-12 col-sm-6 col-md-2">
                                <label htmlFor="inputStatus" className="form-label">
                                    <FontAwesomeIcon icon={faFilter} /> Status
                                </label>
                                <select
                                    className="form-control"
                                    id="inputStatus"
                                    name="status"
                                    onChange={handleChangeStatus}
                                    value={statusSelecionado}
                                >
                                    <option value="Todos">Todos</option>
                                    <option value="Contratado">Contratado</option>
                                    <option value="Pendente">Pendente</option>
                                    <option value="EmAndamento">Em Andamento</option>
                                    <option value="Cancelado">Não Fechado</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card m-0 shadow-lg rounded" >
                {(clientes != null)
                    ?
                    <>
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-striped">
                                <thead className="table-dark">
                                    <tr >
                                        <th scope="col">Codigo</th>
                                        <th scope="col">Razão</th>
                                        <th scope="col">CNPJ</th>
                                        <th scope="col">Cidade</th>
                                        <th scope="col">UF</th>

                                        {
                                            (user?.Perfil != 3) && (
                                                <th scope="col">Vendedor</th>
                                            )
                                        }

                                        {
                                            (user?.Perfil != 6) && (
                                                <th scope="col">Indicação</th>
                                            )
                                        }

                                        <th scope="col">Valor (R$)</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Data Indicação</th>

                                        {
                                            (user?.Perfil == 1) && (
                                                <th scope="col">Data Previsão</th>
                                            )
                                        }

                                    </tr>
                                </thead>
                                <tbody >
                                    {clientesPagina?.map((cliente, index) => (
                                        console.log(`Renderizando cliente ${index}:`, cliente),
                                        <tr key={cliente.idPessoa + (cliente.cliente ?? 'NA')} className={`${cliente?.Cliente?.status === 1 ? 'table-success' : ''} ${cliente?.Cliente?.status === 2 ? 'table-danger' : ''} ${cliente?.Cliente?.status === 3 ? 'table-warning' : ''}`} onClick={user?.Perfil === 6 || user?.Perfil === 1 ? () => handleShowModalIndicado(cliente, user) : undefined}>
                                            <td className="text-truncate" scope="row">{cliente?.Cliente?.codigo?.trim()}</td>
                                            <td className="text-truncate" style={{ "maxWidth": "300px" }}>{cliente?.Cliente?.cliente?.trim()}</td>
                                            <td className="text-truncate">{cliente?.Cliente?.cnpj?.trim()}</td>
                                            <td className="text-truncate" style={{ "maxWidth": "150px" }}>{cliente?.Cliente?.cidade?.trim()}</td>
                                            <td className="text-truncate">{cliente?.Cliente?.Uf?.trim()}</td>

                                            {
                                                (user?.Perfil != 3) && (
                                                    <td className="text-truncate">{cliente?.Cliente?.vendedor?.trim()}</td>
                                                )
                                            }

                                            {
                                                (user?.Perfil != 6) && (
                                                    <td className="text-truncate">{cliente?.Cliente?.indicado?.trim()}</td>
                                                )
                                            }

                                            <td className="text-truncate"> {cliente?.Cliente?.Valor ? new Intl.NumberFormat("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cliente.Cliente.Valor) : "0,00"}</td>
                                            <td className="text-truncate"> {(cliente?.Cliente?.status === 0 ? "Pendente" : cliente?.Cliente?.status === 1 ? "Contratado" : cliente?.Cliente?.status === 2 ? "Não Fechado" : cliente?.Cliente?.status === 3 ? "Em Andamento" : "Desconhecido").trim().toUpperCase()}</td>
                                            <td className="text-truncate"> {cliente?.Cliente?.DataIndicacao ? new Date(cliente.Cliente.DataIndicacao).toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }) : ""}</td>

                                            {
                                                (user?.Perfil == 1) && (
                                                    <td className="text-truncate">{cliente?.Cliente?.DataPrevisao ? new Date(cliente.Cliente.DataPrevisao).toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }) : ""}</td>
                                                )
                                            }

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {totalPaginas > 1 && (
                            <nav aria-label="PageClientes" className="p-1">
                                <ul className="pagination">
                                    <li className={`page-item ${paginaAtual === 1 ? 'disabled' : ''}`}>
                                        <a className="page-link" onClick={() => setPaginaAtual(paginaAtual - 1)}>&laquo;</a>
                                    </li>

                                    {Array.from({ length: totalPaginas }, (_, i) => (
                                        <li key={i} className={`page-item ${paginaAtual === i + 1 ? 'active' : ''}`}>
                                            <a className="page-link" onClick={() => setPaginaAtual(i + 1)}>{i + 1}</a>
                                        </li>
                                    ))}

                                    <li className={`page-item ${paginaAtual === totalPaginas ? 'disabled' : ''}`}>
                                        <a className="page-link" onClick={() => setPaginaAtual(paginaAtual + 1)}>&raquo;</a>
                                    </li>
                                </ul>
                            </nav>
                        )}

                    </>
                    : <div className="table-responsive rounded">
                        <table className="table table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Razão</th>
                                    <th scope="col">Fantasia</th>
                                    <th scope="col">CNPJ</th>
                                    <th scope="col">Cidade</th>
                                    <th scope="col">Uf</th>
                                    <th scope="col">Vendedor</th>
                                    <th scope="col">Valor (R$)</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }

                {
                    (user?.Perfil == 1) && (
                        <div>
                            <div className="d-flex justify-content-end mb-3 me-3">
                                <button className="btn btn-success me-2" onClick={exportToExcel}>
                                    <FontAwesomeIcon icon={faFileExcel} className="me-2" />
                                    Exportar Excel
                                </button>
                                <button className="btn btn-secondary" onClick={exportToTxt}>
                                    <FontAwesomeIcon icon={faFileLines} className="me-2" />
                                    Exportar TXT
                                </button>
                            </div>
                        </div>
                    )
                }

            </div>

            <ModalDetalheClienteIndicado
                showModal={showModal}
                handleCloseModal={handleCloseModalIndicado}
                clienteSelecionado={clienteSelecionada}
                user={user}
            />

        </div >
    );
};

export default Indicacoes;